<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar class="example-toolbar">


    <div fxFlex="40">
      <span class="padding-side mat-h1  primary heading">Billidawn Finmart</span>
    </div>
    <div fxLayoutGap="20px" fxFlex="60">
      <a class="mat-h3 color-primary" color="primary" routerLink="/home" routerLinkActive="is-active">
        Home
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLinkActive="is-active">
        Service
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLinkActive="is-active">
        About Us
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLinkActive="is-active">
        Testimonials
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLink="/contact-us" routerLinkActive="is-active">
        Contact Us
      </a>
      <button mat-raised-button class="example-icon favorite-icon" color="primary">
        Register Now!
      </button>
      <button mat-stroked-button class="example-icon favorite-icon" color="primary">
        Sign In
      </button>

    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 70 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="70">
      <mat-nav-list>
        <!-- <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a> -->

      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <app-footer></app-footer>


    </mat-sidenav-content>
  </mat-sidenav-container>
</div>




<p class="font" fxHide>
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
    <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"> -->
    <!-- <mat-icon>menu</mat-icon> -->
    <!-- </button>  -->
    <div fxFlex="40">
      <span class="mat-h1  primary heading">Billidawn Financial Solution</span>
    </div>
    <!-- <span class="example-spacer"> </span> -->
    <div fxLayoutGap="20px" fxFlex="60">
      <a class="mat-h3 color-primary" color="primary" routerLink="/home" routerLinkActive="is-active">
        Home
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLinkActive="is-active">
        Service
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLinkActive="is-active">
        About Us
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLinkActive="is-active">
        Testimonials
      </a>
      <a class="mat-h3 color-primary" color="primary" routerLink="/contact-us" routerLinkActive="is-active">
        Contact Us
      </a>
      <button mat-raised-button class="example-icon favorite-icon" color="primary">
        Register Now!
      </button>
      <button mat-stroked-button class="example-icon favorite-icon" color="primary">
        Sign In
      </button>

    </div>
  </mat-toolbar>
</p>