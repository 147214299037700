<div class="container cardpadding">
    <div fxLayout="column" fxLayoutGap="20px" fxFlex>
        <div class="background-color-primary" fxLayout="row" fxLayoutAlign="center center">
            <p class="mat-h1 color-white">Contact Us</p>
        </div>
        <div class="contact-us-card" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="border-radius-20">

                <svg viewBox="0 0 500 150" preserveAspectRatio="none" fxHide.lt-sm>
                    <path d="M-1.12,150.48 C19.74,-7.39 343.11,134.70 505.64,-89.30 L500.00,150.00 L0.00,150.00 Z"
                        style="stroke: none; fill: #f3f4ff;"></path>
                </svg>
                <form [formGroup]="contactusFormGroup" class="contact-us-content cardpadding" fxLayout="column">
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>First Name</mat-label>
                                <input formControlName="fname" matInput placeholder="First Name" required>
                                <mat-error>First Name required</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Last Name</mat-label>
                                <input formControlName="lname" matInput placeholder="Last Name" required>
                                <mat-error>Last Name required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Email</mat-label>
                                <input formControlName="email" matInput placeholder="Email" required>
                                <mat-error>Email required</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Phone Number</mat-label>
                                <input formControlName="phone" matInput placeholder="Phone Number" required>
                                <mat-error>Phone Number required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Estimated House Hold Income</mat-label>
                                <input formControlName="household" matInput placeholder="Estimated House Hold Income"
                                    required>
                                <mat-error>Estimated House Hold Income required</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Estimated Credit Card Debt</mat-label>
                                <input formControlName="creditcard" matInput placeholder="Estimated Credit Card Debt"
                                    required>
                                <mat-error>Estimated Credit Card Debt required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Estimated Monthly Credit Card Payments</mat-label>
                                <input formControlName="monthlycredit" matInput
                                    placeholder="Estimated Monthly Credit Card Payments" required>
                                <mat-error>Estimated Monthly Credit Card Payments required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex fxHide>
                        <div fxFlex="48">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Rent or Own</mat-label>
                                <mat-select>
                                    <mat-option value="rent">Rent</mat-option>
                                    <mat-option value="own">Own</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button color="primary" (click)="send()"
                            [disabled]='contactusFormGroup.invalid'>Submit</button>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>