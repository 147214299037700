import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { ServicesComponent } from './components/services/services.component';
import { CommonlayoutComponent } from './layout/commonlayout/commonlayout.component';
import { LayoutModule } from './layout/layout.module';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(
    [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: '',
        component: CommonlayoutComponent,
        children: [
          { path: 'home', component: HomeComponent, pathMatch: 'full' },
          { path: 'about-us', component: AboutUsComponent, pathMatch: 'full' },
          { path: 'services', component: ServicesComponent, pathMatch: 'full' },
          { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
        ]
      },
    ],
    { anchorScrolling: 'enabled' }
  ),
    LayoutModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
