<div class="background-color-white">
    <section fxLayout="row" fxLayoutAlign="space-around center" class="bg padding-bottom">
        <div>
            <div style="line-height: 60px;">
                <p class="title primary mat-h1">Easily manage <br> your finance for <br> daily needs.</p>
            </div>
            <p align="start">Get your account to start spending, <br> sending and saving in minutes</p>
            <button class="button" mat-raised-button color="primary">Download App!</button>
        </div>
        <div>
            <img src="assets/Images/hero2-removebg-preview.png">
        </div>
    </section>
    <!-- card and buttons -->
    <section fxLayout="row" fxLayoutGap="20px" class="full-padding padding-top">
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayoutGap="15px">
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/discover-card.jpg">
                </button>
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/injenico.png">
                </button>
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/GooglePay.png">
                </button>

            </div>
            <div fxLayoutGap="18px">
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/applepay.png">
                </button>
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/master.png">
                </button>
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/visa.png">
                </button>
                <button mat-stroked-button color="primary">
                    <img class="card" src="assets/Images/bitcon.png">
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end">
            <button mat-fab style="background-color:whitesmoke;color: #1A237E;border:1px solid #1A237E"
                aria-label="Example icon button with a home icon">
                <mat-icon>done</mat-icon>
            </button>
            <div fxLayout="column" fxLayoutGap="5px">
                <p class="primary mat-h1 mat-h1">Fast and Secure</p>
                <p>Keep your every transaction safe.</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end">
            <button mat-fab style="background-color:whitesmoke;color: #1A237E;border:1px solid #1A237E"
                aria-label="Example icon button with a home icon">
                <mat-icon>done</mat-icon>
            </button>
            <div fxLayout="column" fxLayoutGap="5px">
                <p class="primary mat-h1 mat-h1">Free to Use</p>
                <p>Available to download in the playstore.</p>
            </div>
        </div>
    </section>
    <!--Our Services  -->
    <div class="full-padding">
        <mat-card class="card-bg ">
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign=" center" class="padding-top">
                <div fxLayout="column" fxFlex="20" fxLayoutGap="20px">
                    <p align="center" class=" mat-h1 sub-heading" style="line-height: 40px;">Our Services</p>
                    <p align="center">Compare rates, crunch numbers and get expert guidance for life's pivotal financial
                        moments.</p>
                </div>
                <mat-card fxLayout="column" fxFlex="20" fxLayoutGap="10px" class="sub-card"
                    fxLayoutAlign="space-around center">
                    <button mat-fab style="background-color:whitesmoke;color: #1A237E;border:1px solid #1A237E"
                        aria-label="Example icon button with a home icon">
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'#d96704'}">savings</mat-icon>
                    </button>
                    <p align="center">Financial Planning and Investments</p>
                </mat-card>
                <mat-card fxLayout="column" fxFlex="20" fxLayoutGap="10px" class="sub-card"
                    fxLayoutAlign="space-around center">
                    <button mat-fab style="background-color:whitesmoke;color: #1A237E;border:1px solid #1A237E"
                        aria-label="Example icon button with a home icon">
                        <mat-icon [ngStyle]="{'color':'#d96704'}">admin_panel_settings</mat-icon>
                    </button>
                    <p align="center">Portfolio Management</p>
                </mat-card>
                <mat-card fxLayout="column" fxFlex="20" fxLayoutGap="10px" class="sub-card"
                    fxLayoutAlign="space-around center">
                    <button mat-fab style="background-color:whitesmoke;color: #1A237E;border:1px solid #1A237E"
                        aria-label="Example icon button with a home icon">
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'#d96704'}">badge</mat-icon>
                    </button>
                    <p align="center">Retirement Planning</p>
                </mat-card>
                <mat-card fxLayout="column" fxFlex="20" fxLayoutGap="10px" class="sub-card"
                    fxLayoutAlign="space-around center">
                    <button mat-fab style="background-color:whitesmoke;color: #1A237E;border:1px solid #1A237E"
                        aria-label="Example icon button with a home icon">
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'#d96704'}">monetization_on
                        </mat-icon>
                    </button>
                    <p align="center">Insurance and Mutual Funds</p>
                </mat-card>
            </div>
        </mat-card>
    </div>

    <!-- What we offer -->
    <div class="full-padding">

        <div class="background-color-primary-light cardpadding border-radius-20" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutAlign="center center">
                <p class="mat-h1 sub-heading">What We Offer</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                <div fxLayout="column" fxLayoutGap="20px" fxFlex="48">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img src="/assets/Images/home/services_1.png" alt="" width="100">
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <p class="mat-h1 color-primary ">Debt Consolidation</p>
                        <p class="mat-h3 width-70">Many Americans have become overwhelmed with debt. Monthly statements
                            from
                            student loans,
                            mortgages, car payments, and credit cards fill the mailboxes of millions of consumers
                            everyday.</p>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="20px" fxFlex="48">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img src="/assets/Images/home/services_2.png" alt="" width="100">
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <p class="mat-h1 color-primary">HELOCs</p>
                        <p class="mat-h3 width-70">HELOC (Home Equity Line of Credit) or simply “home equity credit
                            line,"
                            allows consumers to access the equity in their homes.</p>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                <div fxLayout="column" fxLayoutGap="20px" fxFlex="48">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img src="/assets/Images/home/services_3.png" alt="" width="100">
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <p class="mat-h1 color-primary">Cash Out Refinance</p>
                        <p class="mat-h3 width-70">Many Americans have become overwhelmed with debt. Monthly statements
                            from
                            student loans,
                            mortgages, car payments, and credit cards fill the mailboxes of millions of consumers
                            everyday.</p>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="20px" fxFlex="48">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img src="/assets/Images/home/services_4.png" alt="" width="100">
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <p class="mat-h1 color-primary">401k Loans</p>
                        <p class="mat-h3 width-70">A 401k loan can be a viable option to pay off high interest credit
                            card
                            debt, as most 401k plans offer a loan option.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- better way -->
    <section class="full-padding" fxHide>

        <div fxLayout="row" fxLayoutGap="20px">
            <div>
                <p align="start" class=" mat-h1 sub-heading" style="line-height: 40px;">
                    A better rate is waiting
                </p>
                <p align="start">Bankrate regularly surveys large leaders in all 50 states to determine average rates
                    and help you find the best deal.</p>
            </div>
            <!-- <div><img class="side-img" src="assets/Images/side-image.jpg" /></div> -->
        </div>
        <mat-tab-group color="transparent">
            <mat-tab label="Banking">
                <div fxLayout="row">
                    <div style=" width: 80%;">
                        <canvas style="display: block; width: 80%;" [chartType]="'line'" [legend]="false"
                            [datasets]="chartData" [options]="chartOptions" [labels]="chartLabels" baseChart>
                        </canvas>
                    </div>
                    <div fxFlex>
                        <p align="start" class=" mat-h1 mat-h1" style="line-height: 40px;">
                            Today's Rate
                        </p>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>30 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>15 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>10 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>5/1 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <p class="mat-h1 primary mat-h2 padding-top">See all rates</p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Insurance">
                <div fxLayout="row">
                    <div style="width: 80%">
                        <canvas style=" width: 80%;max-height: 70%;" [chartType]="'line'" [legend]="false"
                            [datasets]="chartData" [options]="chartOptions" [labels]="chartLabels" baseChart>
                        </canvas>
                    </div>
                    <div fxFlex>
                        <p align="start" class=" mat-h1 mat-h1" style="line-height: 40px;">
                            Today's Rate
                        </p>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>30 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>15 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>10 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <ul>
                            <li>5/1 Years Fixed</li>
                        </ul>
                        <mat-divider></mat-divider>
                        <p class="mat-h1 primary mat-h2 padding-top">See all rates</p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </section>

    <!-- Help pepole -->
    <div class="full-padding " fxLayout="column" fxLayoutGap="20px" id="content">
        <div fxLayout="row" fxLayoutAlign="center center">
            <p class="mat-h1 sub-heading">We've helped many people, just like you.</p>
        </div>
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                <div class="cardpadding" fxLayout="row" fxLayoutAlign="center center" fxFlex="50">
                    <img src="/assets/Images/home/assest1.png" alt="" width="200">
                </div>
                <div class="background-color-primary cardpadding border-radius-20" fxLayout="column"
                    fxLayoutAlign="start " fxLayoutGap="10px" fxFlex="50">
                    <p class="mat-h3  color-white">I was burdened with debt after I lost my job. It was a very
                        difficult
                        time
                        for me
                        and my family. I have always been a cautious person and was nervous about calling. I decided to
                        call
                        Prudent anyway and it was the best decision I ever made. From the beginning to the end they were
                        supportive, kind and extremely helpful. I only wish I had approached them earlier. Thanks to
                        them,
                        mine and my family's struggles are over.</p>
                    <p class="color-secondary">~ Howard R. | Ft. Lauderdale Fl</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>

                <div class="background-color-primary cardpadding border-radius-20" fxLayout="column"
                    fxLayoutAlign="start " fxLayoutGap="10px" fxFlex="50">
                    <p class="mat-h3  color-white">Prudent financial helped me through a very difficult time in
                        my life.
                        They were
                        able
                        to provide the right financial vehicle to help me out an overwhelming amount of credit card
                        debt. It
                        would have been a long stressful process if I did this on my own, but with Prudent's support
                        this
                        was a hassle free process! Highly recommend.I was hesitant starting the process but I could not
                        be
                        happier and more relieved that I did. The customer service was exceptional and the process gave
                        me
                        the peace of mind that I much needed. Thank you Prudent!</p>
                    <p class="color-secondary">~ Janice R- Red Bank, NJ</p>
                </div>
                <div class="cardpadding" fxLayout="row" fxLayoutAlign="center center" fxFlex="50">
                    <img src="/assets/Images/home/assest2.png" alt="" width="200">
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                <div class="cardpadding" fxLayout="row" fxLayoutAlign="center center" fxFlex="50">
                    <img src="/assets/Images/home/assest3.png" alt="" width="200">
                </div>
                <div class="background-color-primary cardpadding border-radius-20" fxLayout="column"
                    fxLayoutAlign="start " fxLayoutGap="10px" fxFlex="50">
                    <p class="mat-h3  color-white">I was hesitant starting the process but I could not be
                        happier and more
                        relieved
                        that
                        I did. The customer service was exceptional and the process gave me the peace of mind that I
                        much
                        needed. Thank you Prudent!</p>
                    <p class="color-secondary">~ Richard G. - Los Angeles CA</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>

                <div class="background-color-primary cardpadding border-radius-20" fxLayout="column"
                    fxLayoutAlign="start " fxLayoutGap="10px" fxFlex="50">
                    <p class="mat-h3  color-white">I went to several companies when I was desperate and did not
                        find a
                        solution for
                        my
                        debt anywhere. I came across Prudent Financial Solutions and was hesitant at first but it ended
                        up
                        being the best decision I ever made. They were kind, courteous, respectful and helped me along
                        every
                        step of the way.</p>
                    <p class="color-secondary">~ Beckie M | Omaha, Nebraska</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50">
                    <img class="cardpadding" src="/assets/Images/home/assest4.png" alt="" width="200">
                </div>
            </div>
        </div>
    </div>

    <!-- better way -->
    <section class="full-padding" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50">
            <div>
                <p align="start" class=" mat-h1 sub-heading" style="line-height: 40px;">
                    Start financing with <br> Billidawn Finmart
                </p>
                <p align="start">Sign up today to receive your free digital wallet. You can immediately stat Investing
                </p>
            </div>
        </div>
        <div>
            <form class="example-form">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput placeholder="Ex. pat@example.com">
                </mat-form-field>
            </form>
            <div align="end">
                <button mat-raised-button color="primary">Get Notifications</button>

            </div>
        </div>
    </section>

    <!-- <mat-card fxLayout="column" fxLayoutGap="5px" class="full-padding">
        <div fxLayoutAlign="space-around center" >
            <p class="primary mat-h1 sub-heading">Services</p>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <p></p>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            1
        </div>
    </mat-card> -->
</div>