import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ChartDataSets } from 'chart.js';
// import { ChartDataSets } from 'chart.js';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  @ViewChild('content') contentRef: ElementRef;
  @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

  // Had to make sure each obj had 'hidden' explicitly in it for some reason
  chartData = [
    {
      data: [40, 38, 20, 64],
      label: 'Women',
      borderWidth: 1,
      hidden: false
    },
    {
      data: [27, 45, 62, 12],
      label: 'Men',
      borderWidth: 1,
      hidden: false
    },
    {
      data: [16, 37, 5, 8],
      label: 'Children',
      borderWidth: 1,
      hidden: false
    },
    {
      data: [60, 22, 35, 33],
      label: 'Cats',
      borderWidth: 1,
      hidden: false
    }
  ]
  chartLabels = [
    'Jan', 'Feb', 'Mar', 'Apr'
  ];
  chartOptions = {
    responsive: true,
    responsiveAnimationDuration: 100,
    scales: {
      xAxes: [{
        ticks: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          display: true
        }
      }]
    },
    layout: {
      padding: {
        right: 20
      }
    }
  };

  onSelect(indexItem): void {
    const ci = this.baseChart;
    if (this.chartData[indexItem].hidden === false) { this.chartData[indexItem].hidden = true; }
    else { this.chartData[indexItem].hidden = false; }
    ci.update();

    /** If every dataset's `hidden` key is `true`, re-assign all `hidden` keys with value of `false` */
    if (this.chartData.every(dataset => dataset.hidden === true)) {
      this.chartData.map(eachDataset => Object.assign(eachDataset, { hidden: false }))
      ci.update();
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
