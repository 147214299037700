import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import './../../../../assets/js/smtp.'; //file path may change → 
declare let Email: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactusFormGroup: FormGroup;
  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.contactusFormGroup = this.fb.group({
      fname: new FormControl('', Validators.required),
      lname: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      household: new FormControl('', Validators.required),
      creditcard: new FormControl('', Validators.required),
      monthlycredit: new FormControl('', Validators.required),

    });
  }
  get cuf() { return this.contactusFormGroup.controls; }

  send() {
    Email.send({
      Host: 'smtp.elasticemail.com',
      Username: 'dhruvboghani726@gmail.com',
      Password: '718A9302F96776F3C1E223BB3355301CC1E3',
      To: `${this.cuf.email.value}`,
      From: `dhruvboghani726@gmail.com`,
      Subject: 'Contact Us Details',
      Body: `
    <b>This is sent as a contact us from website.</b> <br/> 
    <b>Name: </b>${this.cuf.fname.value} ${this.cuf.lname.value} <br /> 
    <b>Email: </b>${this.cuf.email.value}<br /> 
    <b>Phone: </b>${this.cuf.phone.value}<br /> 
    <b>Estimated House Hold Income: </b>${this.cuf.household.value}<br /> 
    <b>Estimated Credit Card Debt: </b>${this.cuf.creditcard.value}<br />
    <b>Estimated Monthly Credit Card Payments: </b>${this.cuf.monthlycredit.value}<br />
    <br> <b>~Billidawn Finmart~</b> `
    }).then(message => {
      window.location.reload()
    });

  }

}
