<div class="bg" fxLayout="column" fxLayoutAlign="space-between ">
  <div fxLayout="row" fxLayoutAlign="space-around start" style="padding-top: 30px;">
    <div>
      <p class="font mat-h1 letter-spacing">Learn More</p>
      <p>Terms & Conditions</p>
      <p>FAQs</p>
      <p>Privacy Policy</p>
    </div>
    <div>
      <p class="font mat-h1 letter-spacing">Contact Us</p>
      <!-- <p>Dixesh K. Shah</p> -->
      <p>Mob: 9879268610</p>
      <p>Tel: 079-26605665</p>
      <p>E-mail: dixesh_shah84@yahoo.co.in</p>
    </div>
    <div>
      <p class="font mat-h1 letter-spacing">Get in Touch</p>
      <div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-twitter  color-primary" aria-hidden="true"></i>
          </div>
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-google-plus color-primary" aria-hidden="true"></i>
          </div>
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-facebook color-primary" aria-hidden="true"></i>
          </div>
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-youtube color-primary" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <p align="center" class="font mat-h1 letter-spacing">Billidawn Finmart </p>
  </div>
</div>